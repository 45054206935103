import React from "react";
import logo from "../../imgs/logo.png";

import microsoft from "../../imgs/microsoft.svg";
import { useEffect } from "react";
import { useMsal } from '@azure/msal-react';
let error = "";

const Login = (login) => { // login<<<
    const { instance } = useMsal();
    useEffect(() => {
    const initializeMsal = async () => {
    try {
        await instance.initialize();
    } catch (error) {
        console.error('MSAL initialization error:', error);
    }
};

initializeMsal();
},[instance]);


const signIn = async () => {
    try {
        await login.login();

    } catch (error) {
        console.error('Error signing in:', error);
    }
};


const body = (
    <>
        <div id="loading" />
            <div id="Background">
                <div className="card">
                    <div className="top_card">
                        <img src={logo} className="App-logo" alt="CARE4U 2.0" />
                    </div>
                    <div className="middle_card">
                        <div style={{ height: ".5em", width: "20em", margin: "1em", /* opacity: 0 */ }}>
                            {/* <label>Rimani loggato</label>
                            <input type="checkbox" id="remember" defaultChecked/> */}
                            {error ? <p className="error">{error}</p> : <></>}
                        </div>
                        <div className="link">
                            <div><img src={microsoft} onClick={signIn} alt="microsoft" style={{width:"300px", borderRadius:"25px"}} /></div>
                        </div>
                    </div>
                    <div className="bottom_card">
                        <p>© 2024 - CARE4U 2.0</p>
                    </div>
                </div>
            </div>
    </>
);

return body;
}

export default Login;