import React from 'react';
import { useDrag } from 'react-dnd';

const bedSource = {
    beginDrag(props) {
    // Define the data that will be passed when dragging starts
    return {
        type: 'bed', // A unique identifier for the draggable type
        id: props.id, // Add any relevant ID or data here
    };},
};

function Bed({ width, height, depth, marginLeft, marginTop, index , name}) {

    console.log(name)

    console.log(width, height, depth, marginLeft, marginTop, index , name)

    if (width < 0) width = width * -1;

    const [{ isDragging }, drag] = useDrag({
        type: 'bed',
        item: { id: index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const bedStyle = {
        width: `${width}px`,
        height: `${height}px`,
        depth: `${depth}px`,
        backgroundColor: '#f0f0f0',
        marginTop: `${marginTop}px`,
        marginLeft: `${marginLeft}px`,
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
    };

    const nameStyle = {
        zIndex: 1,
        pointerEvents: 'none',
        color: isDragging ? 'white' : 'black',
        fontWeight: "bold"
    };

    return (
        <div ref={drag} className="bed" style={bedStyle} id="prova">
            {/* Render the name in the center */}
            <div style={nameStyle}>{name}</div>
            {/* Optional: Additional overlay when dragging */}
            {isDragging && (
                <div style={{ position: 'absolute', zIndex: 2, top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none', color: 'red' }}>
                    Moving...
                </div>
            )}

        </div>
    );
}
export default Bed;
